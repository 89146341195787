<!--
* 创建人：罗兴
* 日 期：
* 描 述：临时补助管理
-->
<!--view 模板-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">临时补助管理</div>
    <!--==============================按钮===================================================-->
    <div class="btngroup">
      <el-button type="primary" size="mini" @click="toadd">新增</el-button>
      <el-button type="primary" size="mini" @click="toedit">修改</el-button>
      <el-button type="primary" size="mini" @click="todelete">删除</el-button>
      <el-button type="primary" size="mini" @click="search">查询</el-button>
    </div>
    <div class="sm_search">
      <div class="sm_search_group">
        <div class="sm_search_item">
          <div
            v-html="'学&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号'"
            class="sm_search_title"
          ></div>
          <el-input size="mini" v-model="xh"></el-input>
        </div>
        <div class="sm_search_item">
          <div
            v-html="'姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名'"
            class="sm_search_title"
          ></div>
          <el-input size="mini" v-model="xm"></el-input>
        </div>
        <div class="sm_search_item">
          <div
            v-html="'年&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;级'"
            class="sm_search_title"
          ></div>
          <el-select
            v-model="nj"
            style="display: block; width: 178px"
            size="mini"
            clearable
          >
            <el-option
              v-for="(item, index) in njlist"
              :key="index"
              :label="item.nj"
              :value="item.nj"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="sm_search_group">
        <div class="sm_search_item">
          <div class="sm_search_title">二级学院</div>
          <el-select
            v-model="bm"
            style="display: block; width: 178px"
            size="mini"
            @change="bmchange"
            clearable
          >
            <el-option
              v-for="(item, index) in bmlist"
              :key="index"
              :label="item.bmmc"
              :value="item.bmbh"
            ></el-option>
          </el-select>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">专业名称</div>
          <el-select
            v-model="zy"
            style="display: block; width: 178px"
            size="mini"
            @change="zychange"
            clearable
          >
            <el-option
              v-for="(item, index) in zylist"
              :key="index"
              :label="item.zymc"
              :value="item.zybh"
            ></el-option>
          </el-select>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">班级名称</div>
          <el-select
            v-model="bj"
            style="display: block; width: 178px"
            size="mini"
            clearable
          >
            <el-option
              v-for="(item, index) in bjlist"
              :key="index"
              :label="item.bjmc"
              :value="item.bjbh"
            ></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <!---->
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        stripe
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column prop="xh" label="学号" width="150"> </el-table-column>
        <el-table-column prop="xm" label="姓名" width="100"> </el-table-column>
        <el-table-column prop="nj" label="年级" width="100"> </el-table-column>
        <el-table-column prop="xbm" label="性别" width="100">
          <template slot-scope="scope">
            {{ scope.row.xbm == 1 ? '男' : '女' }}
          </template>
        </el-table-column>
        <el-table-column prop="bmmc" label="二级学院" width="300">
        </el-table-column>
        <el-table-column prop="zymc" label="专业名称" width="350">
        </el-table-column>
        <el-table-column prop="bjmc" label="班级名称" width="300">
        </el-table-column>
        <el-table-column prop="nj" label="年级" width="100"> </el-table-column>
        <el-table-column prop="bzxm" label="补助项目" width="300">
        </el-table-column>
        <el-table-column prop="bzje" label="补助金额" width="200">
        </el-table-column>
        <el-table-column prop="bzrq" label="补助日期" width="200">
        </el-table-column>
        <el-table-column prop="xn" label="学年" width="200"> </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="临时补助登记"
      :visible.sync="dialogFormVisible"
      width="50%"
      :close-on-click-modal="false"
      @close="closeDialog"
      top="5vh"
    >
      <el-dialog
        title="学生信息(双击选择)"
        :visible.sync="dialogStuVisible"
        append-to-body
        :close-on-click-modal="false"
      >
        <div style="display: flex; align-items: center; margin-bottom: 10px">
          <span class="mr5 ml20 fcolor42">学号/姓名:</span>
          <el-input
            v-model="selkeyword"
            placeholder="请输入学号/姓名"
            size="small"
            style="width: 200px"
          ></el-input>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="getStuDataPageList"
            style="margin-left: 10px"
            >搜索</el-button
          >
        </div>
        <el-table
          :data="selectStuData"
          :height="300"
          border
          header-cell-class-name="tableStyle"
          @row-dblclick="selStu"
        >
          <el-table-column
            property="xh"
            label="学号"
            width="150"
          ></el-table-column>
          <el-table-column
            property="xm"
            label="姓名"
            width="150"
          ></el-table-column>
          <el-table-column property="bjmc" label="班级"></el-table-column>
        </el-table>
        <div class="pagebox">
          <el-pagination
            @current-change="handleStuCurrentChange"
            :current-page="stupagination.page"
            :page-size="stupagination.rows"
            layout="total, prev, pager, next, jumper"
            :total="stupagination.records"
          >
          </el-pagination>
        </div>
      </el-dialog>
      <el-scrollbar style="height: 200px" class="myscrollbar">
        <el-form
          :model="forminfo"
          v-if="dialogFormVisible"
          ref="formRef"
          :rules="forminfoRules"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="学号" label-width="150px" prop="XH">
                <el-input v-model="forminfo.XH" @focus="selectstu"> </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="姓名" label-width="150px" prop="XM">
                <el-input v-model="forminfo.XM" readonly> </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="补助项目" label-width="150px" prop="BZXM">
                <el-input v-model="forminfo.BZXM"> </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="补助金额" label-width="150px" prop="BZJE">
                <el-input v-model="forminfo.BZJE" type="number"> </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="补助学年" label-width="150px" prop="XN">
                <el-select v-model="forminfo.XN" style="width: 100%">
                  <el-option
                    v-for="(item, index) in xnlist"
                    :key="index"
                    :label="item.ksnf + '-' + item.jsnf + '学年'"
                    :value="item.ksnf + '-' + item.jsnf + '学年'"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="补助日期" label-width="150px" prop="BZRQ">
                <el-date-picker
                  style="width: 100%"
                  v-model="forminfo.BZRQ"
                  type="date"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-scrollbar>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<!-- javascript脚本-->
<script>
import {
  getXSIndexData,
  GetZYListByQX,
  GetBJListByQX,
  getXSPageList,
} from '../../api/jcxx'
import {
  getLSBZPageList,
  saveLSBZ,
  getLSBZFormData,
  deleteLSBZ,
} from '../../api/zxj'
import { getXNList } from '../../api/system'
export default {
  data() {
    return {
      currxm: '',
      operation: 1,
      dialogFormVisible: false,
      dialogStuVisible: false,
      selkeyword: '',
      keyValue: '',
      xh: '',
      xm: '',
      sfzh: '',
      nj: '',
      njlist: [],
      bm: '',
      bmlist: [],
      zy: '',
      zylist: [],
      bj: '',
      bjlist: [],
      xnlist: [],
      pagination: {
        rows: 15,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight - 100, // 实时屏幕高度
      dataPageList: [],
      tableloading: true,
      forminfo: {
        ID: '',
        XH: '',
        XM: '',
        BZXM: '',
        BZJE: null,
        BZRQ: null,
        XN: '',
      },
      selectStuData: [],
      stupagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      forminfoRules: {
        XH: [{ required: true, message: '请选择学生', trigger: 'blur' }],
        BZXM: [
          {
            required: true,
            message: '请填写补助项目',
            trigger: 'blur',
          },
        ],
        BZJE: [{ required: true, message: '请填写补助金额', trigger: 'blur' }],
        BZRQ: [{ required: true, message: '请选择补助日期', trigger: 'blur' }],
        XN: [{ required: true, message: '请选择学年', trigger: 'blur' }],
      },
      formdata: {},
    }
  },
  created() {
    getXSIndexData().then((res) => {
      if (res.code === 200) {
        this.njlist = res.data.njlist
        this.bmlist = res.data.bmlist
      }
    })
    this.getDataPageList()
  },
  methods: {
    // 获取专业列表
    getZY() {
      GetZYListByQX({ BMBH: this.bm }).then((res) => {
        if (res.code === 200) {
          this.zylist = res.data
        }
      })
    },
    // 获取班级列表
    getBJ() {
      GetBJListByQX({ ZYBH: this.zy }).then((res) => {
        if (res.code === 200) {
          this.bjlist = res.data
        }
      })
    },
    bmchange() {
      this.zy = ''
      this.bj = ''
      this.getZY()
    },
    zychange() {
      this.bj = ''
      this.getBJ()
    },
    search() {
      this.pagination.page = 1
      this.getDataPageList()
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      getLSBZPageList({
        queryJson: JSON.stringify({
          xh: this.xh,
          xm: this.xm,
          nj: this.nj,
          bm: this.bm,
          zy: this.zy,
          bj: this.bj,
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
        this.currxm = ''
      } else {
        this.keyValue = currentRow.id
        this.currxm = currentRow.xm
      }
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    toadd() {
      this.operation = 1
      getXNList().then((res) => {
        if (res.code === 200) {
          this.xnlist = res.data
        }
      })
      this.dialogFormVisible = true
    },
    toedit() {
      this.operation = 0
      if (this.keyValue === '') {
        this.$message.error('未选中需要修改的数据！')
        return false
      } else {
        getLSBZFormData({ id: this.keyValue }).then((res) => {
          if (res.code === 200) {
            this.forminfo.ID = res.data.id
            this.forminfo.XH = res.data.xh
            this.forminfo.BZXM = res.data.bzxm
            this.forminfo.BZJE = res.data.bzje
            this.forminfo.BZRQ = res.data.bzrq
            this.forminfo.XN = res.data.xn
            this.forminfo.XM = this.currxm
            this.dialogFormVisible = true
          }
        })
      }
    },
    todelete() {
      if (this.keyValue === '') {
        this.$message.error('未选中需要修改的数据！')
        return false
      } else {
        deleteLSBZ({ id: this.keyValue }).then((res) => {
          if (res.code === 200) {
            this.$message.success(res.message)
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.$nextTick(() => {
        this.forminfo = JSON.parse(JSON.stringify(this.formdata))
      })
      this.$refs.formRef.resetFields()
    },
    saveForm() {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          saveLSBZ(this.forminfo).then((res) => {
            if (res.code === 200) {
              this.getDataPageList()
              this.dialogFormVisible = false
            }
          })
        }
      })
    },
    // 获取分页信息
    getStuDataPageList() {
      getXSPageList({
        queryJson: JSON.stringify({
          keyword: this.selkeyword,
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.stupagination.page = res.data.page
            this.stupagination.total = res.data.total
            this.stupagination.records = res.data.records
            this.selectStuData = res.data.rows
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    selectstu() {
      if (this.operation === 1) {
        this.dialogStuVisible = true
        this.getStuDataPageList()
      }
    },
    selStu(row, column, event) {
      this.forminfo.XH = row.xh
      this.forminfo.XM = row.xm
      this.dialogStuVisible = false
    },
    handleStuCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.stupagination.page = val
      this.getStuDataPageList()
    },
  },
  mounted() {},
}
</script>
<!-- CSS样式 -->
<style  scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.btngroup {
  height: 28px;
  padding: 3px !important;
  width: 98%;
  margin: 2px auto;
  overflow: hidden;
  min-width: 600px;
  text-align: left;
}
.sm_search {
  padding: initial;
  border-top: 1px solid #ddd !important;
  background-color: #f3f3f3;
  width: 100%;
}
.sm_search_group {
  display: flex;
  padding: 5px 0 5px 0;
}
.sm_search_item {
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 14px;
}
.sm_search_title {
  min-width: 65px;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
  text-align: center;
  font-weight: 700;
}
>>> .el-table .el-table__body tr.el-table__row.current-row td,
.el-table__body tr.current-row > td,
.el-table__body tr.hover-row.current-row > td,
.el-table__body tr.hover-row.el-table__row.current-row > td,
.el-table__body tr.hover-row.el-table__row > td,
.el-table__body tr.hover-row > td {
  background-color: #409eff !important;
  color: white;
}
</style>
